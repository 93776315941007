import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { AuthData } from '../models/auth-data';
import * as firebase from "firebase/auth";


@Injectable({providedIn: "root"})
export class NgFirebaseAuthService  {
    private possibleProviders: any;

    constructor(private afAuth: AngularFireAuth) {
        this.possibleProviders = {
            google_plus: new firebase.GoogleAuthProvider().addScope('email'),
            facebook: new firebase.FacebookAuthProvider().addScope('email'),
            apple: new firebase.OAuthProvider('apple.com'),
            twitter: new firebase.TwitterAuthProvider(),
        };
    }

    loginWithSocialProvider(provider: string): Promise<AuthData> {
        return new Promise((resolve, reject) => {
            const oauthProvider = this.possibleProviders[provider];
            this.afAuth.signInWithPopup(oauthProvider)
                .then(async (result: any) => {
                    let authData = new AuthData(provider, result.credential.accessToken);

                    // Check for Twitter extra info
                    if (result.credential.secret) {
                        authData.secret = result.credential.secret;
                    } else {
                        delete authData.secret;
                    }
                    if (result.credential.idToken) {
                        authData.idToken = await result.user.getIdToken();
                    } else {
                        delete authData.idToken;
                    }
                    if (provider === "apple") {
                        authData.authorizationCode = result.credential.accessToken;
                    } else {
                        delete authData.authorizationCode;
                    }
                    resolve(authData);
                    
                }).catch(error => {
                if (error.code !== "auth/popup-closed-by-user") {
                    resolve(null);
                } else {
                    reject('popup_closed_by_user');
                }
            })
        });
    }

}
